requirejs.config({
    baseUrl: '/assets/javascripts',
    shim: {
        bootstrap: {
            deps: ["jquery"]
        }
    },
    paths: {
        // the left side is the module ID,
        // the right side is the path to
        // the jQuery file, relative to baseUrl.
        // Also, the path should NOT include
        // the '.js' file extension. This example
        // is using jQuery 1.9.0 located at
        // js/lib/jquery-1.9.0.js, relative to
        // the HTML page.
        jquery: 'lib/jquery',
        async: 'lib/async',
        debounce: 'lib/debounce',
        markerclusterer: 'lib/markerclusterer',
        typeahead: 'lib/typeahead',
        bootstrap: '/assets/lib/bootstrap/js/bootstrap.min'
    }
});

define('gmaps', ['async!//maps.google.com/maps/api/js?key=AIzaSyB6IgZauUhFmwH93PijpbXVthUSiz4jQZk'],
    function(){
        // return the gmaps namespace for brevity
        return window.google.maps;
    });

require([
    'jquery',
    'bootstrap',
    'lib/shariff',
    'typeahead',
    'snippet',
    'sidemenu',
    'langSelector',
    'galleryPromotion',
    'verticalPromotion',
    'map',
    'viewCounter',
    'soundcloud',
    'stickyHeader',
    'socialLinks',
    'ads',
    'sidebar',
    'searchbox'
], function($) {

    function adjustBodyHeight() {
        var windowHeight = $(window).height();
        $("body").css("min-height", windowHeight);
    }

    $(window).resize(adjustBodyHeight);
    adjustBodyHeight();

    if(document.cookie.indexOf("loggedIn") >= 0) {
        $("#admin-link").show();
    }
});
define("main", function(){});

