define('socialLinks',["jquery"], function($) {
    var $leaderboard = $(".ad-leaderboard");
    var $header = $("header");
    var $socialLinks = $("#social-links");
    var $document = $(document);

    function updatePosition() {
        var adHeight = 0;
        if($leaderboard.length > 0) {
            adHeight = $leaderboard.height();
        }

        $socialLinks.css("top", adHeight + $header.height() + 62);
    }

    $document.on("etv.adLoaded", function(event) {
        updatePosition();
    });
    updatePosition();
});
