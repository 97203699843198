define('ads',['jquery'], function($) {
    var windowWidth = $(window).width();
    var settings = window.adSettings;

    function trimHorizontalSlots(slots) {
        var minWidth = 0;

        if(windowWidth > 600) {
            minWidth = 600;
        }

        console.log("Trimming horizontal slots");
        console.log(slots);

        var trimmedSlots = [[1, 1]]; // Always include 1x1 px size for third party tags
        for(var i = 0; i < slots.length; i++) {
            var slot = slots[i];
            if(slot[0] <= windowWidth && slot[0] >= minWidth) {
                trimmedSlots.push(slot);
            }
        }
        console.log(trimmedSlots);

        return trimmedSlots;
    }

    function trimVerticalSlots(slots) {
        var maxHeight = 800;

        if(windowWidth <= 1248) {
            maxHeight = 400;
        }

        console.log("Trimming vertical slots");
        console.log(slots);
        var trimmedSlots = [[1, 1]]; // Always include 1x1 px size for third party tags
        for(var i = 0; i < slots.length; i++) {
            var slot = slots[i];
            if(slot[1] <= maxHeight) {
                trimmedSlots.push(slot);
            }
        }
        console.log(trimmedSlots);
        return trimmedSlots;
    }

    if(settings) {
        var googletag = window.googletag;

        googletag.cmd.push(function () {
            googletag.defineSlot('/1052246/etv2_top_board', trimHorizontalSlots([[320, 50], [320, 100], [970, 250], [970, 90], [728, 90]]), 'div-gpt-ad-1470909759736-0').addService(googletag.pubads());
            googletag.defineSlot('/1052246/etv2_vertical_banner', trimVerticalSlots([[300, 250], [300, 600]]), 'div-gpt-ad-1470909887313-0').addService(googletag.pubads());
            googletag.defineSlot('/1052246/etv2_bottom_board', trimHorizontalSlots([[728, 90], [970, 250], [970, 90], [320, 50], [320, 100]]), 'div-gpt-ad-1470909992263-0').addService(googletag.pubads());

            googletag.pubads().setTargeting('token', settings.token);
            googletag.pubads().setTargeting('language', settings.language);
            googletag.pubads().setTargeting('location', settings.location);
            googletag.pubads().setTargeting('channel', settings.channel);
            googletag.pubads().setTargeting('targettag', settings.targettag);

            googletag.pubads().collapseEmptyDivs(true);
            googletag.pubads().enableAsyncRendering();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().addEventListener('slotRenderEnded', function (event) {
                $(document).trigger("etv.adLoaded", event);
            });

            googletag.enableServices();

            googletag.display('div-gpt-ad-1470909759736-0');
            googletag.display('div-gpt-ad-1470909887313-0');
            googletag.display('div-gpt-ad-1470909992263-0');
        });
    }
});
