define('searchbox',['jquery', 'bootstrap', 'typeahead'], function($) {
    var $searchButton = $("#search-button");
    var $searchBox = $("#search-box");
    var $closeButton = $searchBox.find(".fa.fa-close");
    var $input = $searchBox.find("input");

    var url = $searchBox.data("url");

    var selectedItem = null;

    var lang = $("html").attr("lang");

    $input.typeahead({
        source: function(query, callback) {
            selectedItem = null;
            $.get("/"+lang+"/search.json?q=" + query).done(function(response) {
                callback(response);
            });
        },
        matcher: function(item) {
            return true;
        },
        afterSelect: function(item) {
            selectedItem = item;
        },
        click: function(e) {
            e.preventDefault();
            var item = this.$menu.find('.active').data('value');
            goto(item.href, "select");
        },
        autoSelect: false
    });
    $input.keyup(function(e) {
       if(e.keyCode == 13) {
           if(selectedItem !== null) {
               goto(selectedItem.href, 'select');
           } else {
               goto(url + "?q=" + $input.val(), "submit");
           }
       }
    });

    function goto(url, action) {
    // Send event to GTM using dataLayer
        var searchQuery = $input.val();

        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                'event': 'search_event',
                'search_action': action,
                'search_query': searchQuery
            });

    }

        location.href = url;
    }

    var visible = false;
    var timeout;

    function toggleSearchBox() {
        clearTimeout(timeout);

        if(visible) {
            visible = false;

            $searchBox.removeClass("visible");

            timeout = setTimeout(function() {
                $searchBox.css("display", "none");
            }, 600);
        } else {
            visible = true;

            $searchBox.css("display", "block");

            timeout = setTimeout(function() {
                $searchBox.addClass("visible");
                $input.focus();
            }, 100);
        }
    }

    $searchButton.click(toggleSearchBox);
    $closeButton.click(toggleSearchBox);
});
