define('langSelector',['jquery'], function($) {
    var selector = $("#lang-selector");
    var sheet = selector.find(".lang-sheet");
    var visible = false;
    var timeout;

    function toggleSelector() {
        clearTimeout(timeout);

        if(visible) {
            visible = false;

            sheet.removeClass("visible");

            timeout = setTimeout(function() {
                sheet.css("display", "none");
            }, 600);
        } else {
            visible = true;

            sheet.css("display", "block");

            timeout = setTimeout(function() {
                sheet.addClass("visible");
            }, 100);
        }
    }

    selector.find(".lang-button").click(function() {
        toggleSelector();
    });
});
