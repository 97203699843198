define('sidemenu',["jquery"], function($) {
    var sideMenu = $("nav.side-menu");
    var shade = $(".shade");

    function showMenu() {
        sideMenu.show().addClass("visible");
        shade.show().addClass("visible");
    }

    function hideMenu() {
        sideMenu.removeClass("visible");
        shade.removeClass("visible");
        setTimeout(function() {
            shade.hide();
        }, 1000);
    }

    $("header nav .menu-button").click(showMenu);

    shade.click(hideMenu);
});
