define('soundcloud',["jquery", "snippet"], function($) {
    var volume = 0.2;
    var startplay = true;
    var scWidget;

    if($("#etvsound").length > 0) {
        scWidget = SC.Widget('etvsound');
        //console.log('SoundCloud Init');
        scWidget.bind(SC.Widget.Events.READY, function () {
            // skip to random track
            scWidget.skip(Math.floor(Math.random() * 9));
            scWidget.pause();
            scWidget.setVolume(volume);
        });

        window.etvp_onAdFinish = function() {
            //console.log("etvp--------------adFinish--outside iframe");
            scWidget.play();
        };

        window.etvp_onPause = function() {
            //console.log("etvp--------------pause--outside iframe");
            scWidget.pause();
        };

        window.etvp_onAdStart = function() {
            //console.log("etvp--------------adStart--outside iframe");
            scWidget.pause();
        };

        window.etvp_onResume = function() {
            //console.log("etvp--------------resume--outside iframe");
            scWidget.play();
        };
    }
});
