define('verticalPromotion',["jquery"], function($) {
    var $promotion = $(".vertical-promotion");
    var $pages = $promotion.find(".promotable-page");
    var $scrollCont = $promotion.find(".scroll-container");
    var $scrollContInner = $promotion.find(".scroll-container-inner");
    var $pagingLinks = $promotion.find(".paging-indicator i");

    var scrollDirection = 0;
    var lastScrollOffset = 0;
    var isAnimating = false;

    var snapTimeout;

    function updatePageWidth() {
        $pages.css("width", $promotion.width());
        $scrollContInner.css("width", $pages.length * $promotion.width());
        $scrollContInner.css("height", $($pages[0]).outerHeight());
        snapToPage();
    }

    function scrollToPage(page) {
        isAnimating = true;

        $scrollCont.animate({
            scrollLeft: page * $promotion.width()
        }, {
            complete: function() {
                isAnimating = false;
                scrollDirection = 0;
                lastScrollOffset = $scrollCont.scrollLeft();
                updatePaginator();
            }
        });
    }

    function getCurrentPage() {
        return Math.round($scrollCont.scrollLeft() / $promotion.width());
    }

    function updatePaginator() {
        var page = getCurrentPage();
        $pagingLinks.removeClass("fa-circle");
        $pagingLinks.addClass("fa-circle-thin");
        $($pagingLinks[page]).removeClass("fa-circle-thin");
        $($pagingLinks[page]).addClass("fa-circle");
    }

    function snapToPage() {
        var offset = $scrollCont.scrollLeft();

        var page;
        if(scrollDirection < 0) {
            page = Math.floor(offset / $promotion.width());
        } else {
            page = Math.ceil(offset / $promotion.width());
        }

        scrollToPage(page);
    }

    $scrollCont.on({'touchstart': function(e) {
        scrollDirection = 0;
        lastScrollOffset = $scrollCont.scrollLeft();
        $scrollCont.stop(true, false);
        isAnimating = true;
    }});
    $scrollCont.on({'touchend': function() {
        snapToPage();
    }});
    $scrollCont.scroll(function () {
        scrollDirection = scrollDirection + $scrollCont.scrollLeft() - lastScrollOffset;

        if(!isAnimating) {
            clearTimeout(snapTimeout);
            snapTimeout = setTimeout(snapToPage, 500);
        }
    });

    $.each($pagingLinks, function(i, link) {
        var $link = $(link);
        var page = $link.data("page");

        $link.click(function() {
            scrollToPage(page);
        });
    });

    $(window).resize(updatePageWidth);
    updatePageWidth();
});
