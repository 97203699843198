define('map',['jquery', 'debounce', 'gmaps', 'markerclusterer'], function($) {
    var element = $("#map");

    if(element.length < 1) {
        return;
    }

    var params = JSON.parse(atob(element.data("params")));
    var initialLatLng = {
        lat: parseFloat(element.data('lat')),
        lng: parseFloat(element.data('lng'))
    };
    var initialZoom = parseFloat(element.data('zoom'));
    var lang = element.data('lang');
    var target = element.data('target');

    var queryUrl;
    if(target == "Special") {
        queryUrl = "/" + lang + "/programs/query.json?limit=50";
    } else {
        queryUrl = "/" + lang + "/webcams/query.json?limit=50";
    }

    var openedInfo = null;

    var map = new google.maps.Map(element[0], {
        scrollwheel: false,
        zoom: initialZoom,
        center: initialLatLng,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    var markerIndex = {};

    var markerClusterer = new MarkerClusterer(map, [], {
        gridSize: 30,
        styles: [{
            url: '/assets/images/pin_group.png',
            width: 52,
            height: 52
        }]
    });

    function debounce(delay, fn) {
        var timer = null;
        return function () {
            var context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args);
            }, delay);
        };
    }

    map.addListener('bounds_changed', debounce(1000, function() {
        var bottomLeft = map.getBounds().getSouthWest();
        var upperRight = map.getBounds().getNorthEast();

        var top = upperRight.lat();
        var bottom = bottomLeft.lat();
        var left = bottomLeft.lng();
        var right = upperRight.lng();

        params.area = {
          coordinates: [
              {lng: left, lat: top},
              {lng: left, lat: bottom},
              {lng: right, lat: bottom},
              {lng: right, lat: top},
              {lng: left, lat: top}
          ]
        };

        $.ajax({
            method: "POST",
            url: queryUrl,
            data: JSON.stringify(params),
            contentType: "application/json",
            dataType: "json"
        }).done(function(result) {
            result.map(function(obj) {
                if(!markerIndex.hasOwnProperty(obj.id)) {
                    //window.open(obj.href, '_blank');

                    var info = new google.maps.InfoWindow({
                        content: "<div class='map-info-window'>" +
                        "<h3>" + obj.shortTitle + "</h3>" +
                        "<div><a href='"+obj.href+"' target='_blank'><img src='"+obj.imageUrl+"'></a></div>" +
                        "</div>"
                    });

                    var marker = new google.maps.Marker({
                        position: obj.coordinates,
                        //icon: '/assets/images/pin.png'
                    });

                    marker.addListener('click', function() {
                        if(openedInfo !== null) {
                            openedInfo.close();
                        }
                        info.open(map, marker);
                        openedInfo = info;
                    });

                    markerIndex[obj.id] = marker;
                    markerClusterer.addMarker(marker);
                }
                return markerIndex[obj.id];
            });
        });
    }));
});
