define('stickyHeader',["jquery"], function($) {
    var $leaderboard = $(".ad-leaderboard");
    var $header = $("header");
    var $document = $(document);
    var $main = $("main");

    function updateHeader() {
        var adHeight = 0;
        if($leaderboard.length > 0) {
            adHeight = $leaderboard.height();
        }

        var scrollTop = $document.scrollTop();

        var offset = Math.max(0, adHeight - scrollTop);

        $header.css("top", offset);
    }

    $(window).scroll(updateHeader);
    $document.on("etv.adLoaded", function(event) {
        updateHeader();
    });
    updateHeader();
});
