define('galleryPromotion',["jquery"], function($) {
    var promotion = $(".gallery-promotion");

    promotion.find(".tab").click(function() {
        var tab = $(this);

        promotion.find(".tab.active").removeClass("active");
        tab.addClass("active");

        promotion.find("a").hide();

        var index = promotion.find(".tab").index(tab);

        promotion.find("a").eq(index).show();
    });
});
