define('sidebar',['jquery'], function($) {
    $window = $(window);
    $sidebar = $("aside");
    $altPos = $("#alt-aside");

    function updateSidebarPosition() {
        var windowWidth = $window.width();

        if(windowWidth <= 1248) {
            if($altPos.length > 0) {
                $sidebar.detach().appendTo($altPos);
                //$altPos[0].appendChild($sidebar[0]);
            } else {
                $sidebar.remove();
            }
        }
    }

    updateSidebarPosition();
});
