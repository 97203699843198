define('viewCounter',['jquery'], function($) {
    function incrViews(el, path) {
        if(el.length > 0) {
            var id = el.data("id");
            $.ajax({
                url: path + "/" + id + "/views",
                type: 'PUT'
            });
        }
    }

    incrViews($("#page-special"), "/api/v1/specials");
    incrViews($("#page-place"), "/api/v1/places");
});
